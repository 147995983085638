.antMentionsDark {
  textarea {
    --scroll-bar: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --antd-wave-shadow-color: #f5222d;
    -webkit-font-smoothing: antialiased;
    --tw-shadow: 0 0 transparent;
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    touch-action: manipulation;
    font-family: inherit;
    overflow: auto;
    resize: vertical;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 6.5px 11px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    background-color: transparent;
    background-image: none;
    border: 1px solid #434343;
    border-radius: 7px;
    max-width: 100%;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    text-overflow: ellipsis;
    height: 65px;
    max-height: 9.0072e15px;
  }
}
