@import "./styles/tailwind.output.css";
@import "node_modules/rpg-awesome/scss/rpg-awesome";
@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  font-size: 50px;
}

.flicking {
  margin-top: 50px;
}
.flicking .panel,
.flicking .infinite {
  position: relative;
  display: block;
  font-size: 20px;
  width: 300px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #aaa;
}

.ant-card-with-opacity {
  background-color: rgba(20, 20, 20, 0.7) !important;
}

.ant-card-without-padding {
  & .ant-card-body {
    padding: 0;
  }
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}
