.menu {
  display: flex;
  justify-content: center;

  & > li:nth-child(2) {
    position: absolute;
    left: 0;
  }

  & > li:nth-last-child(2) {
    position: absolute;
    right: 0;
  }
}

.menu-disabled {
  &,
  &:hover {
    background-color: transparent !important;
    cursor: default !important;
  }
}

.logo {
  min-width: 300px;
  text-align: center;
  height: calc(100% + 15px);
  -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%);
  background-image: url(https://playerx.qodeinteractive.com/wp-content/uploads/2018/06/logo-background-img.jpg);
  background-size: contain;
}

.img {
  height: 80px;
}
